import React, { useEffect } from 'react';
import clsx from 'clsx';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import InputAdornment from "@material-ui/core/InputAdornment";

// API Request setting
import api from 'services/api-request/api.js';
// core components
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import logobig from "assets/img/logobig.png";
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const useStyles = makeStyles(styles);

export default function RegisterFormPage(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedProvince, setSelectedProvince] = React.useState();
  // let userEmail = "";
  // if (props.location.state) {
  //   userEmail = props.location.state.userEmail;
  // } else {
  //   props.history.push('/register-page');
  // }
  const [userEmail, setuserEmail] = React.useState();
  const [fullname, setFullname] = React.useState();
  const [phone, setPhone] = React.useState();
  const [provinces, setProvinces] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [districts, setDistricts] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedCity, setSelectedCity] = React.useState();
  const [selectedDistrict, setSelectedDistrict] = React.useState();
  const [values, setValues] = React.useState({
    amount: '',
    gender: 1,
    pob: '0',
    address: '0',
    postcode: '0',
    password: '',
    confirmPassword: '',
    weight: '',
    weightRange: '',
    showPassword: false,
    showConfirmPassword: false,
  });
  function doRegister(e) {  
    setIsLoading(true);
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userEmail)) {
      setIsLoading(false);
      alert("Email mengandung karakter spasi atau format tidak valid!");
      
    }else{
      var newphone = phone.replace(/^0+/, '');
      api.post(`register`, {
        name: fullname,
        phone: `62${newphone}`,
        email: userEmail,
        password: values.password,
        conf_password: values.confirmPassword
      })
      .then(function (response) {
        console.log(response.data.data);
        doRegisterProfile(response.data.data.user_id);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.log(error.response);
        if(error.response.data.response_code === "42"){
          alert('Register Failed, Email / Phone number already registered');
        }
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
    }
  };


  function doRegisterProfile(userid) {  
    setIsLoading(true); 
    api.post(`register/profile`, {
      user_id: userid,
      name: fullname,
      gender: values.gender,
      birth_place: values.pob,
      birth_date: selectedDate,
      phone: phone,
      province_id: selectedProvince,
      city_id: selectedCity,
      district_id: selectedDistrict,
      address: values.address,
      postal_code: values.postcode,
      fax: ""
    })
    .then(function (response) {
      console.log(response.data.data);
      props.history.push({pathname: '/login-page', state: {detail: 'Successfully registered, log in using your email.'}});
    })
    .catch(function (error) {
      setIsLoading(false);
      console.log(error);
    })
    .finally(function () {
      props.history.push({pathname: '/login-page', state: {detail: 'Successfully registered, log in using your email.'}});
    });
    console.log("HELO")
  };

  function getProvinces() {  
    api.get(`location/provinces`)
    .then(function (response) {
      response.data.data.map(province => {
        setProvinces(provinces => [...provinces, {
          id: province.id,
          name: province.name
      }]);
    });
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  };
  function getCities(provinceId) {  
    console.log(provinceId);
    api.get(`location/cities?province=` + provinceId)
    .then(function (response) {
      response.data.data.map(city => {
        setCities(provinces => [...provinces, {
          id: city.id,
          name: city.name
      }]);
    });
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  };
  function getDistricts(provinceId, cityId) {  
    api.get(`location/districts?province=` + provinceId + "&city=" + cityId)
    .then(function (response) {
      response.data.data.map(district => {
        setDistricts(provinces => [...provinces, {
          id: district.id,
          name: district.name
      }]);
    });
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(function () {
      // always executed
    });
  };

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleDateChange = (date) => {
    if(date) {
      setSelectedDate(moment(date).format("YYYY-MM-DD").toString());
    }
  };

  const onProvinceChange = (event, province) => {
    if (province) {
      cities.length = 0;
      setSelectedCity("");
      setSelectedDistrict(null);
      getCities(province.id);
      setSelectedProvince(province.id);
    }
  }

  const onCityChange = (event, city) => {
    if (city) {
      districts.length = 0;
      getDistricts(selectedProvince, city.id);
      setSelectedCity(city.id);
    }
  }

  const onDistrictChange = (event, district) => {
    if (district) {
      setSelectedDistrict(district.id);
    }
  }

  useEffect(() => {
    getProvinces();
  }, []);

  return (
    <div style={{backgroundColor:"white"}}>
        <div className={classes.container} style={{color:"black", textAlign:"center", paddingTop:"8%"}}>
        <img src={logobig} alt="logo-jonas"/>
        <div style={{color:"black", padding:"30px", fontSize:"19px"}}>
        <b>Register and let's get started</b>
        </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
                <form className={classes.form} >
                <TextField onChange={e => setuserEmail(e.target.value)} id="outlined-basic" label="Email" variant="outlined" fullWidth style={{paddingBottom:"5%"}} required={true}/>
                  <TextField onChange={e => setFullname(e.target.value)} id="outlined-basic" label="Full name" variant="outlined" fullWidth style={{paddingBottom:"5%"}} required={true}/>
                  {/* <FormControl variant="outlined" className={classes.formControl} style={{paddingBottom:"5%", width:"100%"}} >
                    <InputLabel id="demo-simple-select-outlined-label">Gender</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      fullWidth
                      value={values.gender}
                      onChange={handleChange('gender')}
                      label="Gender"
                      style={{textAlign:"left"}}
                      required={true}
                    >
                      <MenuItem value={1}>Male</MenuItem>
                      <MenuItem value={0}>Female</MenuItem>
                    </Select>
                  </FormControl> */}
                  {/* <TextField onChange={handleChange('pob')} id="outlined-basic" label="Birthplace" variant="outlined" fullWidth style={{paddingBottom:"5%"}} required={true}/>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DatePicker
                      fullWidth
                      animateYearScrolling
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/DD/yyyy"
                      id="date-picker"
                      label="Date of Birth"
                      value={selectedDate}
                      disableFuture={true}
                      onChange={date => handleDateChange(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{paddingBottom:"5%"}}
                    />
                  </MuiPickersUtilsProvider> */}
              <TextField placeholder='Contoh: 815xxxx' onChange={e => setPhone(e.target.value)} id="outlined-basic" type="number" label="Phone Number" variant="outlined" fullWidth style={{ paddingBottom: "5%" }} InputProps={{
                startAdornment: (
                  <InputAdornment position="start">62</InputAdornment>
                ),
              }}required={true}/>
                  {/* <TextField onChange={handleChange('postcode')} id="outlined-basic" label="Postal Code" variant="outlined" fullWidth style={{paddingBottom:"5%"}} required={true}/>
                  <TextField onChange={handleChange('address')} id="outlined-basic" label="Address" variant="outlined" fullWidth style={{paddingBottom:"5%"}} required={true}/> */}
                  {/* <Autocomplete
                        id="autocomplete-location"
                        getOptionLabel={(option) => option.name}
                        options={provinces}
                        openOnFocus
                        onChange={onProvinceChange}
                        renderInput={(params) => 
                        <TextField {...params}
                            variant="outlined"
                            label="Province"
                            style={{paddingBottom:"5%"}}
                            required={true}
                        />}
                    />
                    <Autocomplete
                        id="autocomplete-location"
                        getOptionLabel={(option) => option.name}
                        options={cities}
                        openOnFocus
                        onChange={onCityChange}
                        renderInput={(params) => 
                        <TextField {...params}
                            variant="outlined"
                            label="City"
                            style={{paddingBottom:"5%"}}
                            required={true}
                        />}
                    />
                    <Autocomplete
                        id="autocomplete-location"
                        getOptionLabel={(option) => option.name}
                        options={districts}
                        openOnFocus
                        onChange={onDistrictChange}
                        renderInput={(params) => 
                        <TextField {...params}
                            variant="outlined"
                            label="District"
                            style={{paddingBottom:"5%"}}
                            required={true}
                        />}
                    /> */}
                  <FormControl className={clsx(classes.margin, classes.textField)} style={{paddingBottom:"5%", width:"100%"}} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                    id="outlined-adornment-password"
                    fullWidth
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    required={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={71}
                  />
                </FormControl>
                <FormControl className={clsx(classes.margin, classes.textField)} style={{paddingBottom:"6%", width:"100%"}} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                    <OutlinedInput
                    id="outlined-adornment-password"
                    fullWidth
                    type={values.showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onChange={handleChange('confirmPassword')}
                    required={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={138}
                  />
                </FormControl>
                <div>
                  <Button type="submit" disabled={fullname && phone !== null && values.password !== '' && values.confirmPassword !== '' ?false : true} onClick={doRegister}
                  style={ fullname &&  phone && values.password !== '' && values.confirmPassword !== '' ? {width:"80%", height: "50px", backgroundColor:"green"} : {width:"80%", height: "50px", backgroundColor:"gray"}}>Register</Button>
                </div>
                {/* <div>
                  <Button type="submit" disabled={fullname && phone && selectedProvince && selectedCity && selectedDistrict && values.gender !== null && values.password !== '' && values.confirmPassword !== '' && values.pob !== '' && values.address !== '' && values.postcode !== '' && selectedDate ? false : true} onClick={doRegister}
                  style={ fullname &&  phone && selectedProvince && selectedCity && selectedDistrict && values.gender !== null && values.password !== '' && values.confirmPassword !== '' && values.pob !== '' && values.address !== '' && values.postcode !== '' && selectedDate ? {width:"80%", height: "50px", backgroundColor:"green"} : {width:"80%", height: "50px", backgroundColor:"gray"}}>Register</Button>
                </div> */}
                </form>
            </GridItem>
          </GridContainer>
        </div>
    </div>
  );
}
